import Vue from "vue";
export default {
    updateAccountGroupCategories: async groupDto => {
        try {
            var response = await Vue.axios.post(`categories/group`, groupDto);
        } catch (e) {
            console.log("🚀 ~ file: accountCategories.api.js ~ updateAccountGroupCategories: ~ e", e);
            return [];
        }
        return response.data;
    },

    updateAccountCategories: async categoryDto => {
        try {
            var response = await Vue.axios.post(`categories/category`, categoryDto);
        } catch (e) {
            console.log("🚀 ~ file: accountCategories.api.js ~ updateAccountCategories: ~ e", e);
            return [];
        }
        return response.data;
    },

    updateIcons: async iconsDto => {
        try {
            var response = await Vue.axios.post(`categories/icons`, iconsDto);
        } catch (e) {
            console.log("🚀 ~ file: accountCategories.api.js ~ updateIcons: ~ e", e);
            return [];
        }
        return response.data;
    },

    getAll: async (type, search) => {
        try {
            const response = await Vue.axios.get(`categories`, {
                params: {
                    type,
                    search,
                },
            });
            return response;
        } catch (e) {
            console.log("🚀 ~ file: accountCategories.api.js ~ getAll: ~ e", e);
            return [];
        }
    },

    getAccountPlans: async accountPlanType => {
        try {
            const { data } = await Vue.axios.get(`categories/accountplans/${accountPlanType}`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: accountCategories.api.js ~ getAccountPlans: ~ e", e);
            return false;
        }
    },

    getVatOptions: async () => {
        try {
            const { data } = await Vue.axios.get(`categories/vatList`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: accountCategories.api.js ~ getVatOptions: ~ e", e);
            return false;
        }
    },

    getIconOptions: async () => {
        try {
            const { data } = await Vue.axios.get(`categories/icon`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: accountCategories.api.js ~ getIconOptions: ~ e", e);
            return false;
        }
    },

    deleteIcon: async id => {
        try {
            const { data } = await Vue.axios.delete(`categories/icon/${id}`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: accountCategories.api.js ~ deleteIcon: ~ e", e);
            return false;
        }
    },

    deleteAccountCategory: async id => {
        try {
            const { data } = await Vue.axios.delete(`categories/category/${id}`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: accountCategories.api.js ~ deleteAccountCategory: ~ e", e);
            return false;
        }
    },

    deleteAccountGroupCategory: async id => {
        try {
            const { data } = await Vue.axios.delete(`categories/group/${id}`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: accountCategories.api.js ~ deleteAccountGroupCategory: ~ e", e);
            return false;
        }
    },
};
