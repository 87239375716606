<template>
    <div>
        <div class="card-tail"><TopBar @reloadList="updateUrlAndGetAll()" /></div>
        <div class="card-tail"><AccountCategoryList :category-data="accountCategoriesData" @reloadList="updateUrlAndGetAll()" /></div>
    </div>
</template>
<script>
import queryString from "query-string";
import Api from "./accountCategories.api";

export default {
    data() {
        return {
            accountCategoriesData: [],
            query: "",
            search: "",
        };
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Kategorier");
        document.title = "Kategorier";
    },

    components: {
        AccountCategoryList: () => import(/* webpackChunkName: "AccountCategoryList" */ "./components/AccountCategoryList.vue"),
        TopBar: () => import(/* webpackChunkName: "TopBar" */ "./components/TopBar.vue"),
    },

    created() {
        this.updateUrlAndGetAll();
    },

    methods: {
        updateUrlAndGetAll() {
            const { type, search } = queryString.parse(window.location.search, { sort: false });
            this.query = type || "Enskildfirma";
            this.search = search;
            this.getAll();
        },

        async getAll() {
            this.$waiting.start("loading");
            const response = await Api.getAll(this.query, this.search);
            this.accountCategoriesData = response.data;
            this.$waiting.end("loading");
        },
    },
};
</script>
